.coursePage{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* min-height: 90vh; */
    padding: 0 15%;
    padding-top: 40px;
    text-align: center;
}
.coursePage>*{
    margin: 1rem 0;
}
.banner-img{
    height: 255px;
    width: 500px;
}
.c-head{
    font-weight: unset;
    font-size: 24px;
    max-width: 720px;
    line-height: 32px;
    color: rgb(72, 72, 94);
    font-size: 22px;
    line-height: 32px;
    /* letter-spacing: 1px; */
}
.c-subhead{
    font-size: 18px;
    max-width: 720px;
    line-height: 28px;
    color: rgba(72, 72, 94, 0.7);
}
.btn-explore{
    padding: 10px 10px;
    font-size: 23px;
    margin-top: 1rem;
    font-weight: 600;
    z-index: 1;
    color: white;
    border: none;
    background: #002e60;
    border-radius: 4px;
    cursor: pointer;
}
.btn-explore-parent{
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 0.5rem 0;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: rgb(0 0 0 / 10%) 0px -1px;
}
.btn-ex-desc{
    margin: 12px;
    font-weight: unset;
    position: absolute;
    padding-top: 1rem;
    padding-left: 22rem;
    font-size: 12px;
    line-height: 16px;
    color: rgb(72, 72, 94);
    font-family: sans-serif;
}

@media(max-width:700px){
    .c-head{
        font-size: 20px;
        line-height: 32px;
    }
    .c-subhead{
        font-size: 17px;
        line-height: 25px;
    }
    .btn-ex-desc{
        display: none;
    }
    .btn-explore-parent{
        background-color: none;
        box-shadow: none;
        position: relative;
    }
    .btn-explore{
        padding: 13px 10px;
        font-size: 21px;
    }
}
@media(max-width:600px){
    .banner-img{
        width: 100%;
        height: auto;
        height: 231.5px;
    width: 463px;
    }
    .coursePage{
        height: 100vh;
        justify-content: center;
    }
    .c-head{
        font-size: 17px;
    line-height: 25px;
    }
    .c-subhead{
        font-size: 16px;
    line-height: 24px;
    }
    .btn-ex-desc{
        display: none;
    }
    .btn-explore-parent{
        background-color: none;
        box-shadow: none;
        position: fixed;
        bottom: 0;
    }
    .btn-explore{
        padding: 13px 10px;
        font-size: 16px;
        width: 100%;
        margin: 0 1rem;
    }
}
@media(max-width:500px){
    .banner-img{
        width: 100%;
        height: auto;
    }
}