*{
    font-family: "Source Sans Pro", sans-serif;
}
.Explore-Form{
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: 0 15%;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.head{
    font-size: 24px;
    line-height: 32px;
    color: rgb(72, 72, 94);
}
.subhead{
    text-align: center;
    margin: 0 1rem;
    padding: 0 20px;
    margin-bottom: 0.5rem;
    max-width: 100%;
    width: inherit;
    font-weight: unset;
    font-size: 20px;
    line-height: 28px;
    color: rgba(72, 72, 94, 0.7);
}

.btn-st{
    width: 9rem;
    height: 3.1rem;
    font-size: 23px;
    margin-top: 1rem;
    font-weight: 600;
    color: white;
    border: none;
    background: #002e60;
    border-radius: 4px;
    cursor: pointer;
}

#img-vectorquiz{
    display: block;
    margin: 0px auto;
    height: 262.8px;
}

.btn-desc{
    margin: 12px;
    max-width: 100%;
    width: inherit;
    font-weight: unset;
    font-size: 12px;
    line-height: 16px;
    color: rgb(72, 72, 94);
    font-family: sans-serif;
}

.logo{
    margin: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    /* width:120px */
}

@media (max-width: 500px){
    #img-vectorquiz{
        width: 85%;
        height: auto;
    }

    .Explore-Form{
        margin: 0 5%;
        margin-top: 30px;
        height: 90vh;
    }

    .head{
        font-size: 20px;
        line-height: 25px;
    }

    .subhead{
        font-size: 16px;
        line-height: 20px;
    margin: 0;
    padding: 0;
    }

    .btn-st-parent{
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        position: fixed;
        bottom: 0;
        background-color: rgb(225 223 223);;
    box-shadow: rgb(0 0 0 / 10%) 0px -1px;
    }
    .btn-st{
        margin: 8px 0;
    }
}