html{
    scroll-snap-type: y mandatory;
}
.Form{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}
.Form-quest{
    display: flex;
    scroll-snap-align: start;
    height: 100vh;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    text-align: left
}

.required{
    color: red;
}
.quest{
    font-size: 24px;
    line-height: 32px;
    color: rgb(72, 72, 94);
}
.sub-quest{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 20px;
    line-height: 28px;
    color: rgba(72, 72, 94, 0.7);
}
.Textfield{
    padding: 0px 0px 8px;
    font-size: 30px;
}
#standard-basic{
    font-size: 30px;
    color: #002e60;
    border-color: #002e60;
    margin: 5px;
    margin-top: 1rem;
}
.btn-ok{
    width: 73px;
    cursor: pointer;
    height: 40px;
    margin-top: 10px;
    font-size: 20px;
    color: white;
    font-weight: 500;
    border: none;
    background-color: #002e60 ;
    padding: 6px;
    border-radius: 3px;
}
.options{
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 1rem;
}
.option{
    display: flex;
    border: 2px solid #AEC6CF;
    font-size: 18px;
    border-radius: 4px;
    padding: 4px;
    margin: 4px 0;
    opacity: 1;
    color: #002e60;
    background-color: #bed5de;
    box-shadow: rgb(34, 112, 196 / 60%) 0px 0px 0px 1px inset;
    width: auto;
    text-align: start;
    align-items: center;
}
.option:hover{
    background-color: #c3e0ed;
    border: 2px solid #002e60;
    cursor: pointer;
    /* border: none; */
}
.option-num{
    border-radius: 3px;
    border: 1px solid grey;
    padding: 2px 5px;
    font-size: 16px;
    margin: 10px;
    margin-left: 5px;
    background-color: white;
    border-color: #002e60;
    font-weight: 700;
}

.btn-prev{
    width: 50px;
    height: 2rem;
    background-color: #002e60;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 3px;
    position: fixed;
    border-radius: 3px 0 0 3px;
    bottom: 0;
    right: 0;
    margin-bottom: 35px;
    margin-right: 87px;
}
.btn-next{
    width: 50px;
    height: 2rem;
    background-color: #002e60;

    border: none;
    cursor: pointer;
    color: white;
    border-radius: 0 3px 3px 0;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 35px;
    margin-right: 35px;
}

.progressBar{
    color: cyan;
    position: fixed;
    top: 0;
    width: 100%;
}

.form-main{
    display: flex;
}

.form-q-num{
    display: flex;
    margin-right: 10px;
    color: #002e60;
}
.q-num{
    margin-top: 8px;
    margin-right: 4px;
}
.form-q-num>svg{
    margin-top: 11px;
    fill: #002e60;
}

.opt-tick{
    width: 2rem;
    margin: 0 10px;
    margin-left: auto;
}

@keyframes blinkingFrames {
    0% {opacity: 1.00;}
    50% {opacity: 0.00;}
    100% {opacity: 1.00;}
}

.blinking {
    border: 2px solid;
    animation: 0.25s ease 0s 2 normal none running blinkingFrames;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border-color, opacity, box-shadow;
    transition-duration: 0.1s;
    opacity: 1;
}
.blinking:hover{
    border: 2px solid;
}
.logo{
    margin: 1rem;
    position: fixed;

}

.error{
    margin: 5px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 2px 10px;
    font-weight: unset;
    font-size: 14px;
    line-height: 20px;
    background: rgb(255 188 188);
    font-family: sans-serif;
    color: red;
}

.footer{
    position: fixed;
    bottom: 0;
    text-align: center;
    background: #002e60;
    color: white;
    height: 2rem;
    display: flex;
    /* padding: 10px; */
    width: 100%;
    justify-content: center;
    align-items: center;
}


.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

@media (max-width: 700px){
    .Form-quest{
        width: 90%;
    }
    .btn-desc{
        display: none;
    }
}

@media (max-width: 500px)  {
    .logo>img{
        width: 100px;
        height: 50px;
    }

    .option{
        font-size: 16px;
    line-height: 24px;
    }

    .quest{
        font-size: 20px;
        line-height: 22px;
    }

    .Form-quest{
        width: 100%;
    }
    #standard-basic {
        font-size: 22px;
    }
    .form-main{
        padding: 15px;
    }
    .btn-ok{
     font-size: 16px;
    }
    .btn-desc{
        display: none;
    }
    .sub-quest{
        font-size: 15px;
        line-height: 24px;
        margin-bottom: 0;
    }
}

.hide{
    display: none;
}

.disabled{
    background-color: #b2b0bf;
    cursor: not-allowed;
    pointer-events: all !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
